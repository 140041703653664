<template>
  <v-dialog v-model="showFormDialog" max-width="50vw" @click:outside="closeForm">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="form.fname" prepend-icon="person" :label="$t('fname')"
                  :rules="rules.fname"></v-text-field>
                <v-text-field v-model="form.name" prepend-icon="person" :label="$t('lname')"
                  :rules="rules.name"></v-text-field>
                <v-text-field v-model="form.email" prepend-icon="email" :label="$t('group.email')"
                  :rules="rules.email"></v-text-field>
                <v-select v-model="form.gender" prepend-icon="mdi-gender-male-female" :items="gender" item-text="text"
                  item-value="id" :label="$t('norm.gender')" :rules="rules.gender"></v-select>
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="bday" :label="$t('birthdate')" prepend-icon="mdi-calendar" readonly
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="birth_date" @input="menu2 = false"></v-date-picker>
                </v-menu>

                <!-- to add nr questions in edit scenario -->
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="form.groups" prepend-icon="mdi-account-group" :items="forSelect(data.groups)"
                  item-text="text" item-value="id" multiple :label="$t('group.group')"></v-select>
                <v-textarea v-model="form.notes" prepend-icon="notes" :label="$t('group.notes')"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending || savedItem != null" @click="closeForm">{{ $t("Cancel") }}</v-btn>
          <v-btn type="submit" :disabled="sending || savedItem != null" color="primary">{{ $t("Save") }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear v-if="sending" color="deep-purple accent-4" indeterminate rounded
        height="6"></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
    $t(errorMessage)
  }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
    $t("group.participant saved", savedItem)
        }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import validate from "@/plugins/validate";
import moment from "moment";

export default {
  mixins: [crudMixin],
  props: ["gid", "data"],
  mounted() {
    if (this.gid) {
      this.defaultItem.groups = [parseInt(this.gid)];
    }
    this.defaultItem.age = 50;
  },
  data() {
    return {
      apiQuery: "/participant/",
      gender: [
        { id: "male", text: this.$t("gender_male") },
        { id: "female", text: this.$t("gender_female") },
        { id: "other", text: this.$t("gender_other") },
      ],
      menu2: false,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("lname"),
            }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("lname"),
            }),
        ],
        fname: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              fname: this.$t("fname"),
            }),
          () =>
            this.getInputError("fname", {
              min: 2,
              fname: this.$t("fname"),
            }),
        ],
        email: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("administrators.Email"),
            }),
          (v) => {
            return (
              validate.email.test(v) || this.$t("administrators.Invalid e-mail")
            );
          },
          () =>
            this.getInputError("email", {
              name: this.$t("administrators.Email"),
            }),
        ],
        gender: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("norm.gender"),
            }),
        ],
      },
      birth_date: null,
    };
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? this.$t("group.Add participant")
        : this.$t("group.Edit participant");
    },
    bday() {
      if (!this.birth_date) {
        return "";
      }
      return moment(this.birth_date).format("DD.MM.Y");
    },
  },

  watch: {
    birth_date: function (val) {
      this.form.birth_date = val;
    },
  },

  methods: {
    increment() {
      var n = parseInt(this.form.age, 10);
      if (isNaN(n)) {
        n = this.defaultItem.age;
      }
      if (n < 5) {
        n = 5;
      }
      if (n > 150) {
        n = 150;
      }
      this.$set(this.form, "age", n + 1);
    },
    decrement() {
      var n = parseInt(this.form.age, 10);
      if (isNaN(n)) {
        n = this.defaultItem.age;
      }
      if (n < 5) {
        n = 5;
      }
      if (n > 150) {
        n = 150;
      }
      this.$set(this.form, "age", n - 1);
    },
    onDataLoaded() {
      if (this.form.birth_year) {
        this.form.age = new Date().getFullYear() - this.form.birth_year;
      } else {
        this.form.age = this.defaultItem.age;
      }
      this.birth_date = this.form.birth_date;
    },
  },
};
</script>

<style scoped>
.v-menu__content .v-card {
  margin-bottom: 0;
  margin-top: 0;
}
</style>
