var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"footer-props":{
    'items-per-page-options': [20, 50, 100],
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [(item.end_time)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onViewAnswers(item)}}},[_vm._v("mdi-eye")]):_vm._e()]}},{key:"item.email_sent",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.email_sent).format("LLL"))+" ")]}},{key:"item.end_time",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.end_time ? _vm.moment(item.end_time).format("LLL") : "-")+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }