<template>
  <v-dialog v-model="showDialog" max-width="30vw">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>

      <v-card-text>
        <slot v-bind="itemToDelete"
          v-if="!deletedItem"
          >{{$t('confirm delete')}}</slot
        >
      </v-card-text>

      <v-card-actions v-if="!deletedItem">
        <slot name="buttons">
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            :disabled="sending || deletedItem != null"
            @click="showDialog = false"
            >{{$t('no')}}</v-btn
          >
          <v-btn
            color="red darken-1"
            :disabled="sending || deletedItem != null"
            @click="performDelete"
            >{{$t('yes')}}</v-btn
          >
        </slot>
      </v-card-actions>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <slot :message="errorMessage" name="error">
        <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
          typeof errorMessage === 'string' ? $t(errorMessage) : $t(errorMessage.message)
        }}</v-alert>
      </slot>
      <slot v-bind="deletedItem" name="confirmed">
        <v-alert v-if="deletedItem" tile type="success" class="mb-0">{{
          confirmation
        }}</v-alert>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudDelete";

export default {
  mixins: [crudMixin],
  props: {
    url: {
      type: String,
      default: "/",
    },
    title: {
      type: String,
      default: "Delete item",
    },
    confirmation: {
      type: String,
      default: "Item successfully deleted",
    },
  },

  created() {
    this.apiQuery = this.url;
  },
};
</script>
