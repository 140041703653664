<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :server-items-length="total"
    :footer-props="{
      'items-per-page-options': [20, 50, 100],
    }"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small v-if="item.end_time" class="mr-2" @click="onViewAnswers(item)"
        >mdi-eye</v-icon
      >
    </template>
    <template v-slot:[`item.email_sent`]="{ item }">
      {{ moment(item.email_sent).format("LLL") }}
    </template>
    <template v-slot:[`item.end_time`]="{ item }">
      {{ item.end_time ? moment(item.end_time).format("LLL") : "-" }}
    </template>
  </v-data-table>
</template>

<script>
import crudMixin from "mixins/crudTable";
import moment from "moment";

export default {
  mixins: [crudMixin],
  props: ["pid"],
  components: {},
  data() {
    return {
      apiQuery: "/quiz-results/",
      headers: [
        { text: this.$t("result.uuid"), value: "quiz_participant_id" },
        { text: this.$t("result.quiz name"), value: "quiz_name" },
        { text: this.$t("result.email time"), value: "email_sent" },
        { text: this.$t("result.end time"), value: "end_time" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
    };
  },
  created() {
    this.options.pid = this.pid;
    this.options.sortBy = ['email_sent'];
    this.options.sortDesc = [true];
    moment.locale(this.$i18n.locale);
  },
  methods: {
    moment,
    onViewAnswers(item) {
      this.$router.push("/results/answers/" + item.id);
    },
  },
};
</script>
