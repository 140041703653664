<template>
  <v-container id="user-table" fluid tag="section">
    <participants-form :data="meta" ref="editForm" @itemSaved="onParticipantSaved"></participants-form>
    <planning-form :data="meta" :pid="pid" ref="editSchedule" @itemSaved="onScheduleSaved"
      @serverError="alertError"></planning-form>
    <delete-dialog ref="deleteConfirm" url="/quiz-schedule/" :title="$t('result.Delete schedule')"
      :confirmation="$t('result.Schedule deleted')" @itemDeleted="onScheduleSaved">
      <template v-slot:default="item">{{ $t("result.confirm delete schedule", item) }}</template>
    </delete-dialog>
    <v-row>
      <v-col sm="12" md="6" lg="3">
        <v-card v-if="participant.name" elevation="3">
          <v-card-title class="primary darken-1 white--text">
            <v-icon color="white" class="mr-2"> mdi-account </v-icon>
            {{ participant.name }}
            <v-spacer> </v-spacer>
            <a @click="onEditParticipant" v-if="meta">
              <v-icon small color="white hover">mdi-pencil</v-icon>
            </a>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                {{ participant.email }}
              </v-list-item>
              <v-list-item>
                {{ $t("gender_" + participant.gender) }} - {{ age }}
              </v-list-item>
              <v-list-item>
                {{ participant.notes }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="12" md="6" lg="9">
        <v-card v-if="participant.name" elevation="3">
          <v-card-title class="primary darken-1 white--text">
            <v-icon color="white" class="mr-2"> mdi-file-question </v-icon>
            {{ $t("result.programmed") }}
          </v-card-title>
          <v-card-text class="mt-5">
            <v-simple-table v-if="have_schedules">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("result.uuid") }}
                    </th>
                    <th class="text-left">
                      {{ $t("result.set") }}
                    </th>
                    <th class="text-left">
                      {{ $t("result.planned") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Actions") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in schedules" :key="item.name">
                    <td>{{ item.id }}</td>
                    <td v-if="meta.sets">{{ meta.sets[item.set_id] }}</td>
                    <td v-else>{{ item.set_id }}</td>
                    <td>{{ moment(item.email_time).format("LLL") }}</td>
                    <td>
                      <v-icon small class="mr-2" @click="onEditSchedule(item)">mdi-pencil</v-icon>
                      <v-icon small class="mr-2" @click="onDeleteSchedule(item)">mdi-delete</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <span v-else>
              {{ $t("result.no schedules") }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn color="primary" dark @click="addSchedule">
              <v-icon left>mdi-calendar-plus</v-icon>
              {{ $t("result.schedule") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" md="12">
        <v-card v-if="participant.name" elevation="3">
          <v-card-title class="primary darken-1 white--text">
            <v-icon color="white" class="mr-2"> mdi-compare-vertical </v-icon>
            {{ $t("result.quiz results") }}
            <div class="spacer"></div>
            <div>
              <v-btn color="blue-grey" class="white--text" @click="onDownloadTable">
                <v-icon small color="white hover" :title="$t('result.download table')">mdi-download</v-icon>
                {{ $t("result.export") }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <results-table :pid="pid" ref="resultsTable">
            </results-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ParticipantsForm from "@/pages/clients/ParticipantsForm";
import PlanningForm from "@/pages/clients/PlanningForm";
import DeleteDialog from "@/components/crud/DeleteDialog";
import ResultsTable from "@/pages/results/QuizResultsTable";
import moment from "moment";

export default {
  components: {
    ParticipantsForm,
    PlanningForm,
    DeleteDialog,
    ResultsTable
  },
  data() {
    return {
      pid: 0,
      meta: {},
      participant: {},
      schedules: [],
    };
  },
  computed: {
    age() {
      if (this.participant.birth_year) {
        return new Date().getFullYear() - this.participant.birth_year;
      }
      return "";
    },
    have_schedules() {
      return this.schedules.length > 0;
    },
  },
  created() {
    this.pid = this.$route.params.id;
    this.get("/participant/" + this.pid).then((data) => {
      this.participant = data;
    });
    this.get("/participant/" + this.pid + "/edit").then((data) => {
      this.meta = data;
    });
    this.get("/quiz-schedule/" + this.pid).then((data) => {
      this.schedules = data;
    });
    moment.locale(this.$i18n.locale);
  },
  methods: {
    moment,
    onEditParticipant() {
      this.$refs.editForm.editItem(this.participant, 0);
    },
    onEditSchedule(item) {
      this.$refs.editSchedule.editItem(item, 0);
    },
    onParticipantSaved(item) {
      this.participant = item;
    },
    addSchedule() {
      this.$refs.editSchedule.addItem();
    },
    onScheduleSaved() {
      this.get("/quiz-schedule/" + this.pid).then((data) => {
        this.schedules = data;
      });
      this.$refs.resultsTable.reloadData();
      setTimeout(() => {
        this.$refs.editSchedule.closeForm();
      }, 1000);
    },
    onDeleteSchedule(item) {
      this.$refs.deleteConfirm.deleteItem(item, 0);
    },
    alertError(error) {
      this.alert(this.$t(error.message), 'error');
    },
    onDownloadTable() {
      this.download(
        "/quiz-results-export/" + this.pid,
        this.participant.name +
        "-" +
        this.moment().format("D-M-Y_H-m") +
        ".xlsx"
      ).catch((error) => {
        this.alert({
          text: error.message,
          type: "error",
          title: "Oops",
        });
      });
    },
  },
};
</script>
