<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="form.set_id"
                  prepend-icon="mdi-calendar-question"
                  :items="forSelect(data.sets)"
                  item-text="text"
                  item-value="id"
                  :label="$t('result.set')"
                  @change="loadSet"
                  required
                  :disabled="editedItem ? true : false"
                ></v-select>
                <v-checkbox
                  v-if="!editedItem"
                  v-model="form.send_now"
                  :label="$t('result.send now')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="form.notices"
                  prepend-icon="notes"
                  :label="$t('group.notes')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-date-picker
                  v-if="!form.send_now"
                  v-model="form.dates"
                  :multiple="!editedItem"
                  full-width
                  :min="today"
                  locale="de-de"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-col>
              <v-col cols="12" md="6">
                <v-time-picker
                  v-if="!form.send_now"
                  v-model="form.time"
                  full-width
                  format="24hr"
                ></v-time-picker>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="form.email_subject"
                  :label="$t('quiz.email subject')"
                ></v-text-field>
                <v-textarea
                  v-model="form.email_message"
                  :label="$t('quiz.email message')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null || !form.set_id"
            color="primary"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("result.schedule saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";
import moment from "moment";

export default {
  mixins: [crudMixin],
  props: ["pid", "data"],

  data() {
    return {
      apiQuery: "/quiz-schedule/",
      rules: {},
      defaultItem: {
        send_now: true,
        time: "08:00",
      },
      reloadBeforeEdit: true,
    };
  },

  mounted() {
    this.defaultItem.pid = this.pid;
    moment.locale(this.$i18n.locale);
  },

  computed: {
    today() {
      return this.moment(new Date()).format("Y-MM-DD");
    },
    formTitle() {
      return this.editedItem === null
        ? this.$t("result.Add schedule")
        : this.$t("result.Edit schedule");
    },
  },

  methods: {
    moment,
    loadSet() {
      this.get("/quiz-sets/" + this.form.set_id).then((data) => {
        this.$set(this.form, "email_subject", data.email_subject);
        this.$set(this.form, "email_message", data.email_message);
        this.$set(this.form, "notices", data.notices);
      });
    },
  },
};
</script>
